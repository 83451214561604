:root {
    --levven-blue:#0077C6;
    --levven-light-blue: #57C8E7;
    --levven-green: #1D944B;
    --levven-lime: #98C848;
    --nearly-black: #303030;
    --dark-grey: #565759;
    --faded: #EFEFF0;
    --white: #ffffff;
}

.card{
    width:100%;
    background-color: var(--white);
    padding: 1.2rem;
    border-radius: 5px;
    box-shadow: 1px 2px 2px -2px rgba(0 ,0 ,0 ,20%),
                1px 2px 2px 0px rgba(0 ,0 ,0 ,14%),
                1px 1px 3px -2px rgba(0 ,0 ,0 ,12%);
    position:relative;
}

.card-header {
    width: 100%;
    display: flex;
    justify-content: space-between;

}

.card-header-section-1 {
    display: flex;
    align-items: center;
    gap: 1em;
}

.card-header-section-2 {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 1em;
}

/* .card-icon-blue{
    background-color: var(--levven-blue);
} */

.card-icon-blue {
    /* border: 2px solid var(--levven-blue); */
}

.card-icon-green{
    background-color: var(--levven-green);
}

/* .card-icon{
    padding:0.75em;
    border-radius: 5px;
    color:var(--white);
    box-shadow: 1px 2px 2px -2px rgba(0 ,0 ,0 ,20%),
    1px 2px 2px 0px rgba(0 ,0 ,0 ,14%),
    1px 1px 3px -2px rgba(0 ,0 ,0 ,12%);
    width:fit-content;
    line-height: 0;
} */

.card-icon{
    padding:0.75em;
    border-radius: 5px;
    color:var(--levven-blue);
    /* box-shadow: 1px 2px 2px -2px rgba(0 ,0 ,0 ,20%),
                1px 2px 2px 0px rgba(0 ,0 ,0 ,14%),
                1px 1px 3px -2px rgba(0 ,0 ,0 ,12%); */
    width:fit-content;
    line-height: 0;
}

.card-title{
    width:100%;
    display:flex;
    font-weight: var(--title-weight);
    font-size: 1.5em;
}

.links {
    display: flex;
    gap: 1em;

}

.links button{
    /* color: var(--levven-blue); */
    /* font-weight: var(--link-weight); */
    /* font-size: 1.1em; */
    text-decoration: none;
    display: flex;
    
    align-items: center;
    padding: 1em 1.5em 1em 1.5em;
    
}

/* .links button:hover {
    background-color: var(--faded);
    border-radius: 5px;
    cursor: pointer;
    
} */

.card-content {
    padding: 2em 0em;
    margin-bottom: 2em;
}

.card-content table{
    border: 0;
    width: 100%;
    border-collapse: collapse; 
}

.card-content table tr td,
.card-content table tr th{
    text-align: left;
    padding: .7em;
}

.card-content table tr{
    border-bottom: 1px solid rgba(86 87 89 / 50%);
}

.card-content table tbody tr:last-child {
    border:none;
}

.card-content table tr th {

    /* font-weight: 600; */
    color:var(--dark-grey);
    opacity: 0.8;
}


.grid-container {
    display: grid;
    gap: 1em;
    grid-template-columns: repeat(2,1fr);
}

.grid-container p:last-child {
    margin-top: 0.6em;
}

.grid-container .page-title {
    margin-bottom: 2em;
}

.grid-span-1 {
    grid-column: span 1
}

.grid-span-2{
    grid-column: span 2;
}

.user-name {
    /* font-weight: var(--title-weight); */
}

.user-information-card {
    /* font-size: 1.1em; */
}

table {
    
}

.page-title h1{
    font-weight: var(--title-weight);
    font-size: 2.5em;
}

.card-content tbody tr:hover{
    cursor: pointer;
    background-color: rgba(224, 224, 224, 0.5);
}

@media (max-width: 1100px){
    .grid-container {
        display: grid;
        gap: 1em;
        grid-template-columns: repeat(1,1fr);
    }

    .grid-span-2{
        grid-column: span 1;
    }
    
}

@media (max-width: 600px){
    .card-header {
        display: block;
    }
    
    .card-header-section-2{
        padding-top: 0.6em;
    }
}


@media (max-width: 400px){
    .card-header-section-1 {
        display: block;
    }

    .card-title {
        padding-top:0.6em;
    }

    .links {
        display: block;
    }

    .card-title {
        padding-top: 0.6em;
    }


    .links button:last-child{
        margin: 0.6em 0em 0em 0em;
    }

}



