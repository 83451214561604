.upload-main-div {
    width: 100%;
    padding: 0.7em;
    margin-bottom: 20px;
}

.upload-section-1 {
    display: flex;
    gap:1em;
}

.upload-div-preview video{
   max-width: 500px;
}

.upload-div-preview {
    overflow: auto;
}

.upload-div-file {
    display: flex;
    align-items: flex-end;
    padding-bottom: 1em;
    overflow: auto;

}

.edit-section-1 {
    display: flex;
    gap: 1em; 
    overflow: auto;
    padding: 1.2em 0em;
}

.edit-section-1 iframe{
    max-width: 500px;
}


.edit-section-1-2 {
    display: flex;
    justify-content:space-between;
    flex-direction:column;
    padding: 1em 0em;
}



.video_options{
    display: flex;
    gap: 0.5em;

}

.icon {
    cursor: pointer;
}

@media (max-width:1200px){
    .upload-section-1 {
        display: block;
    }

    .edit-section-1 {
        display: block;
    }
}

@media (max-width: 800px) {
    .upload-div-preview video{
        max-width: 300px;
    }

    .edit-section-1 iframe{
        max-width: 300px;
    }
}

@media (max-width: 650px) {
    .upload-div-preview video{
        max-width: 250px;
    }

    .edit-section-1 iframe{
        max-width: 250px;
    }
}