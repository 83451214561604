* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  line-height: 24px;
  letter-spacing: 1px;
}

.ql-size-small {
  font-size: 0.75em;
}
.ql-size-large {
  font-size: 1.25em;
}
.ql-size-huge {
  font-size: 2 em;
}
.ql-direction-rtl {
  direction: rtl;
  text-align: right;
}
.ql-align-center {
  text-align: center;
}
.ql-align-justify {
  text-align: justify;
}
.ql-align-right {
  direction: rtl;
  text-align: right;
}

iframe.ql-video {
  padding: 3em;
  width: 75%;
  min-height: 500px;
}

@media (max-width: 1500px) {
  iframe.ql-video {
    padding: 3em;
    width: 100%;
    min-height: 500px;
  }
}

ul {
  margin-left: 50px;
}

ol {
  margin-left: 50px;
}

.table-container {
  max-height: 75vh;
}

.table-container-filters {
  max-height: 48vh;
}

@media only screen and (max-height: 1000px) {
  .table-container {
    max-height: 72vh;
  }

  .table-container-filters {
    max-height: 45vh;
  }
}

@media only screen and (max-height: 800px) {
  .table-container {
    max-height: 64vh;
  }

  .table-container-filters {
    max-height: 37vh;
  }
}

@media only screen and (max-height: 600px) {
  .table-container {
    max-height: 56vh;
  }

  .table-container-filters {
    max-height: 29vh;
  }
}

@media only screen and (max-height: 500px) {
  .table-container {
    max-height: 48vh;
  }

  .table-container-filters {
    max-height: 21vh;
  }
}
.grecaptcha-badge {
  visibility: hidden;
}

.recaptcha-text-styles {
  color: rgb(184, 184, 184);
  font-size: smaller;
}

.recaptcha-text-styles a {
  color: rgb(0, 166, 217);
}
